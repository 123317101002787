<template>
  <StoryblokComponent
    v-if="story"
    :blok="story.content"
    :name="story.name"
    :uuid="story.uuid"
    :slug="story.full_slug"
  />
</template>

<script setup lang="ts">
const route = useRoute();
const config = useRuntimeConfig();
const { slug } = route.params;

const isPreview = computed(
  () => config.public.NUXT_PUBLIC_NODE_ENV !== 'production'
);

const story = await useAsyncStoryblok(
  slug && Array.isArray(slug) && slug.length > 0 ? slug.join('/') : 'home',
  {
    version: isPreview.value ? 'draft' : 'published',
    resolve_relations: [
      'Activity.locations',
      'Group.locations',
      'Group.cta_banner',
      'ContentTopActivities.activities',
      'Blog.locations',
      'Blog.activities',
      'Wizard.group',
      'Wizard.locations',
      'Wizard.activities',
      'Package.locations',
      'Package.activities',
    ],
  }
);

// SEO
if (story.value) {
  useSeoMeta({
    title: story.value.content.meta_title,
    description: story.value.content.meta_description,
    ogTitle: story.value.content.meta_title,
    ogDescription: story.value.content.meta_description,
    twitterTitle: story.value.content.meta_title,
    twitterDescription: story.value.content.meta_description,
    twitterCard: 'summary' as const,
    ogImage:
      story.value.content.feature_image?.filename ||
      'https://a.storyblok.com/f/308362/2730x1536/d324d052fa/ripey-castle-axe-throwing.png',
    twitterImage:
      story.value.content.feature_image?.filename ||
      'https://a.storyblok.com/f/308362/2730x1536/d324d052fa/ripey-castle-axe-throwing.png',
  });
}

useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
  ],
});
</script>
